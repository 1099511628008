import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './Button';

function MainScreen() {
    return (
        <div className="main-screen section-margin">
            <div className="main-screen__container">
                <h1 className="main-screen__title">
                    <span>Тендерное сопровождение</span>
                    от Титовой Елены
                </h1>
                <p className="main-screen__text">
                    Успешно пройдем все этапы торгов - <span>без процента </span>
                    за выигранные тендеры!
                </p>
                <Button link="/prices">Выбрать услугу</Button>
                <div className="main-screen__wrap-img">
                    <StaticImage
                        className="main-screen__img"
                        layout="fixed"
                        formats={['auto', 'webp', 'avif']}
                        src="../images/elena_titova.png"
                        quality={100}
                        alt="Elena Titova"
                        placeholder="blurred"
                    />
                </div>
            </div>
        </div>
    );
}

export { MainScreen };
