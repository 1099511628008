import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Fancybox from './Fancybox';

function Diploms() {
    const data = useStaticQuery(graphql`
        query diplomsImageFull {
            allFile(filter: { absolutePath: { regex: "/(diploms)/(full)/" } }) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(
                            height: 220
                            formats: [AUTO, WEBP, AVIF]
                            layout: FIXED
                            placeholder: BLURRED
                            quality: 80
                        )
                        original {
                            src
                        }
                    }
                    name
                }
            }
        }
    `);

    return (
        <div className="diploms section-margin">
            <Swiper
                modules={[Navigation, Pagination]}
                navigation={false}
                pagination={{ el: '.swiper-pagination', type: 'bullets', clickable: true }}
                breakpoints={{
                    640: {
                        navigation: {
                            prevEl: '.swiper-button-prev',
                            nextEl: '.swiper-button-next',
                        },
                    },
                }}
                lazy={true}
                slidesPerView="auto"
            >
                {data.allFile.nodes.map((file, i) => {
                    const img = getImage(file.childImageSharp.gatsbyImageData);
                    return (
                        <SwiperSlide key={file.name}>
                            <a
                                data-fancybox="gallery"
                                aria-label={file.childImageSharp.name}
                                href={file.childImageSharp.original.src}
                            >
                                <GatsbyImage image={img} alt={file.name} className="diploms__img" />
                            </a>
                        </SwiperSlide>
                    );
                })}

                <div className="swiper-wrap-navigation-pagination">
                    <div className="swiper-button-prev">
                        <svg width="66" height="15" viewBox="0 0 66 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.292953 6.79289C-0.0975723 7.18342 -0.0975723 7.81658 0.292953 8.20711L6.65691 14.5711C7.04744 14.9616 7.6806 14.9616 8.07113 14.5711C8.46165 14.1805 8.46165 13.5474 8.07113 13.1569L2.41428 7.5L8.07113 1.84315C8.46165 1.45262 8.46165 0.819457 8.07113 0.428932C7.6806 0.0384078 7.04744 0.0384078 6.65691 0.428932L0.292953 6.79289ZM65.0486 6.5L1.00006 6.5V8.5L65.0486 8.5V6.5Z" />
                        </svg>
                    </div>
                    <div className="swiper-pagination" />
                    <div className="swiper-button-next">
                        <svg width="66" height="15" viewBox="0 0 66 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M64.846 8.20712C65.2365 7.81659 65.2365 7.18343 64.846 6.7929L58.482 0.428942C58.0915 0.038418 57.4583 0.0384179 57.0678 0.428942C56.6773 0.819466 56.6773 1.45263 57.0678 1.84316L62.7246 7.50001L57.0678 13.1569C56.6773 13.5474 56.6773 14.1806 57.0678 14.5711C57.4583 14.9616 58.0915 14.9616 58.482 14.5711L64.846 8.20712ZM0.0903319 8.5L64.1389 8.50001L64.1389 6.50001L0.0903322 6.5L0.0903319 8.5Z" />
                        </svg>
                    </div>
                </div>
            </Swiper>
        </div>
    );
}

export { Diploms };
