import * as React from 'react';
import { useState } from 'react';
import { Layout } from '../components/Layout';
import { MainScreen } from '../components/MainScreen';
import { Title } from '../components/Title';
import { CardNumber } from '../components/CardNumber';
import { Partners } from '../components/Partners';
import { Form } from '../components/Form';
import { Seo } from '../components/Seo';
import { Diploms } from '../components/Diploms';
import figureLogo from '../images/figure-logo.svg';
import { Autoplay, EffectCards } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

function IndexSite({ location }) {
    const [visibleExample, setVisibleExample] = useState(false);

    return (
        <Layout location={location}>
            <MainScreen />
            <div className="advantages section-margin">
                <div className="container">
                    <div className='advantages__grid'>
                        <div className='advantages__grid-col-title'>
                            <Title className="h1 advantages__title" level="h2">
                                Компания ГЛАВТОРГИ оказывает услуги <span>комплексного</span><span>&nbsp;</span><span>сопровождения</span><span>&nbsp;</span><span>тендеров</span> для всех форм бизнеса
                            </Title>
                        </div>
                        <div className='advantages__grid-col-swiper'>
                            <div className='advantages__swiper'>
                                <Swiper
                                    modules={[Autoplay, EffectCards]}
                                    effect='cards'
                                    grabCursor='true'
                                    height='auto'
                                    autoplay={{
                                        delay: 2000
                                    }}
                                >

                                    <SwiperSlide>
                                        <div className="advantages__item">
                                            <div className="advantages__item-wrap-content">
                                                <img src={figureLogo} alt="Главторги" />
                                                <div className="advantages__item-content">
                                                    <span>Экономим ваше время и силы</span>Вам не нужно разбираться в сложной
                                                    документации и законодательстве - Сосредоточьтесь на своих клиентах, а заботы о
                                                    том, как грамотно и вовремя подать заявку на участие в тендере мы берем на себя!{' '}
                                                    <span
                                                        style={{
                                                            color: 'var(--accent-color)',
                                                            textDecoration: 'underline',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => setVisibleExample((prev) => !prev)}
                                                    >
                                            {!visibleExample ? 'Пример' : 'Скрыть'}
                                        </span>
                                                </div>
                                            </div>

                                            {visibleExample && (
                                                <div className="advantages__item-example">
                                                    <p>
                                                        Для клиента появился хороший тендер на 20 млн. и он хочет в нем
                                                        поучаствовать, но проанализировав тендер, мы видим, что там установлены
                                                        такие критерии как наличие опыта, кадров, МТР, которых у клиента очень мало
                                                        или совсем нет. К тому же в нем обязательно обеспечение заявки на 300 тыс.
                                                        руб.
                                                    </p>
                                                    <p>
                                                        Клиент думает, что сейчас он купит банковскую гарантию, на которую потратит
                                                        150 т.р. или например внесет эти деньги 300 тыс. руб в качестве обеспечения
                                                        заказчику, (таким образом заморозит их минимум на 3 недели) и все он
                                                        победил. Нет! Это будет пустая трата денег на гарантию и заморозка средств.
                                                        Таким образом, мы можем дать точную консультацию, стоит ли ему участвовать в
                                                        нем вообще)
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="advantages__item">
                                            <div className="advantages__item-wrap-content">
                                                <img src={figureLogo} alt="Главторги" />
                                                <div className="advantages__item-content">
                                                    <span>Практично</span>Тендерный отдел по цене 1 сотрудника - Вам не нужно
                                                    содержать целый штат специалистов по тендерам - все эти люди есть у нас!
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="advantages__item">
                                            <div className="advantages__item-wrap-content">
                                                <img src={figureLogo} alt="Главторги" />
                                                <div className="advantages__item-content">
                                                    <span>Выгодно</span>Стоимость наших услуг в 2 раза ниже цен аналогичных услуг
                                                    на рынке + Мы не берем процент с выигранного тендера, позволяя клиенту
                                                    рассчитывать на всю прибыль с тендера!
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="advantages__item">
                                            <div className="advantages__item-wrap-content">
                                                <img src={figureLogo} alt="Главторги" />
                                                <div className="advantages__item-content">
                                                    <span>Консультация 24/7</span>Оперативно ответим на все ваши вопросы в любое
                                                    время. Участвуем даже в ночных торгах.
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div className="container">
                <Title className="h1" level="h2">
                    В период с 2021 по 2022 год
                </Title>
                <div className="section-margin">
                    <CardNumber title="На участие в тендерах было подано" number="1600" text="заявок" />
                    <CardNumber title="Заключено договоров на сумму более" number="600" text="миллионов рублей!" />
                </div>
                <Title className="h1" level="h2">
                    Наши клиенты
                </Title>
            </div>
            <Partners />
            <div className="container">
                <Title className="h1" level="h2">
                    Дипломы и сертификаты
                </Title>
                <Diploms />
            </div>

            <Form />
        </Layout>
    );
}

export default IndexSite;

export function Head() {
    return (
        <Seo
            title="Тендерное сопровождение под ключ - помощь в участии в тендерах - ГЛАВТОРГИ"
            description="Комплексное тендерное сопровождение закупок - целый тендерный отдел по цене 1 сотрудника! 15 лет практики. Не берем процент с выйгранных тендеров! Тендерное бюро Титовой Елены."
        />
    );
}
