import * as React from 'react';

const CardNumber = ({ title, number, text }) => {
    return (
        <div className="card-numbers">
            <div className="card-numbers__title">{title}</div>
            <div className="card-numbers__content">
                <span className="card-numbers__number">{number}</span>
                <span className="card-numbers__text">{text}</span>
            </div>
        </div>
    );
};

export { CardNumber };
