import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

function Partners() {
    const data = useStaticQuery(graphql`
        query partnersImage {
            allFile(filter: { absolutePath: { regex: "/(partners)/" } }) {
                edges {
                    node {
                        id
                        publicURL
                        name
                    }
                }
            }
        }
    `);

    return (
        <div className="partners section-margin">
            <div className="container">
                <Swiper
                    modules={[Autoplay]}
                    spaceBetween={80}
                    slidesPerView="auto"
                    loop
                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: false,
                    }}
                    lazy
                >
                    {data.allFile.edges.map((file) => (
                        <SwiperSlide key={file.node.id}>
                            <img className="partners__img" src={file.node.publicURL} alt={file.node.name} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export { Partners };
